import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Layout from './index';
import {utcTime, sortByProperty, Copyright} from '../utils/MantawiTime'; 

 

export default function AboutPage() {
  return (
 <Layout>
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          miniens beta
        </Typography>
        <Link to="/">Go to the main page</Link>
        {/**
        <ProTip />
         */}
        <Copyright />
      </Box>
    </Container>
    </Layout>
  );
}
